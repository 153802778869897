<script>
import ProspectForm from './ProspectForm.vue'
import InputField from '@/components/general/InputField'
import ProspectPictureManage from './ProspectPictureManage.vue'
import { required, email, requiredIf, maxValue, minLength, maxLength } from 'vuelidate/lib/validators'
import SectionBlock from '../../components/general/SectionBlock.vue'
import { getCountries } from '@/support/countryinfo/country-codes.js'
import Loading from '@/components/general/Loading'
import { mapActions } from 'vuex'

export default {
  name: 'ProspectInviteNew',
  components: {
    Loading,
    InputField,
    ProspectForm,
    ProspectPictureManage,
    SectionBlock
  },
  props: {
    prospectInfo: {
      type: Object,
      default: () => ({})
    },
    hash: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isReady: true,
      step: 0,
      userAvatar: '',
      universitySearch: '',
      showForm: false,
      universityOptions: [],
      courseSearch: '',
      courseOptions: [],
      companySearch: '',
      companyOptions: [],
      jobTitleSearch: '',
      jobTitleOptions: [],
      profileInfo: {},
      positionsCategory: [],
      languageList: {},
      showImportResumeModal: false,
      tempArea: null,
      initialSocialMedia: null,
      socialMediaOptions: {
        Linkedin: 'https://www.linkedin.com/',
        Instagram: 'https://www.instagram.com/',
        Twitter: 'https://www.twitter.com/',
        Facebook: 'https://www.facebook.com/',
        GitHub: 'https://www.github.com/',
        Tiktok: 'https://www.tiktok.com/',
        Dribbble: 'https://www.dribbble.com/',
        Behance: 'https://www.behance.com/',
        Blog: 'https://',
        Site: 'https://'
      },
      initialFormData: {},
      passwords: {
        password: '',
        confirmPassword: ''
      },
      inviteId: '',
      formData: {
        emailResponse: null,
        countryCode: '',
        categories: [],
        cityOptions: [],
        cityIdOptions: {},
        name: 'TEste',
        lastName: '',
        city: '',
        birthdate: '',
        region: '',
        skills: [],
        countryAbbr: '',
        email: '',
        phone: '',
        isPCD: false,
        pcdType: '',
        pcdCID: '',
        needAdaptation: false,
        pcdAdaptation: '',
        socialMedia: [],
        professionalTitle: '',
        professionalDescription: '',
        preferredAreas: [],
        hasProfessionalExp: true,
        professionalExperiences: [],
        hasAcademicExp: true,
        academicExperiences: [],
        languages: [],
        useTermConfirmation: false
      }
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage (language) {
      this.setLanguage(language)
    },
    handleForm () {
      this.$v.formData.$touch()
      if (this.$v.formData.$error) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'error',
          title: this.$t('global:validation.form.required')
        })
      } else this.step = 2
    },
    formatMonthYearToTimestamp (date) {
      if (!date) return date
      const month = date.split('/')[0]
      const year = date.split('/')[1]
      return `${year}-${month}-01T00:00:00.000Z`
    },
    formatYearToTimestamp (date) {
      if (date === '' || !date) return date
      return `${date}-01-01T00:00:00.000Z`
    },
    importNotifiedInfo () {
      const info = {
        hash: this.$route.params.hash,
        body: { allowedImport: true }
      }
      this.isReady = false
      this.$store.dispatch('attemptCandidateProspect', info).then(response => {
        if (response.user) {
          const formatedResponse = this.convertFormDataProspect(response.user)
          const formatedResponse2 = { ...response, ...{ user: formatedResponse } }
          this.formatInfoToFormData(formatedResponse2)
        } else {
          this.choose = 1
          const formatedResponse = this.convertFormDataProspect(response.profile, response.company)
          const formatedResponse2 = { ...response, ...{ user: formatedResponse } }
          this.formatInfoToFormData(formatedResponse2)
        }
        this.isReady = true
        this.step = 1
      })
    },
    countryToCode (country) {
      const countriesInfo = getCountries()
      const referenceObject = {}
      Object.keys(countriesInfo).forEach(el => { referenceObject[el] = countriesInfo[el].dialCode })
      return referenceObject[country]
    },
    async login () {
      try {
        this.attemptingLogin = true
        const response = await this.$store.dispatch('attemptPerformLogin', { email: this.formData.email, password: this.passwords.password, workspace: this.$route.query.theme })
        localStorage.setItem('plooral-global', JSON.stringify({ token: response.data.token }))
        const userData = response.data.user
        if (response.data) {
          try {
            this.$store.getters.getUserWorkspaces.map(workspace => {
              if (this.$route.name.includes(workspace.type)) {
                if (workspace.type === 'individual' || (this.$route.params.companyDomain && this.$route.params.companyDomain.toLowerCase() === workspace.subdomain.replace(/\s+/g, '').toLowerCase())) {
                  this.$store.commit('setSelectedWorkspace', workspace)
                }
              }
            })
            const updateObject = this.updateHandler()
            this.$store.dispatch('attemptUpdateUserProfile', updateObject).then(() => {
              Promise.all([
                this.$store.dispatch('attemptUpdateProspectUserToPosition', this.$route.params.hash),
                this.uploadImage()
              ]).then((data) => {
                if (data[2] && data[2].image) {
                  this.$store.dispatch('attemptUpdateUserGlobalInfo', { avatar: data[2].image })
                  this.$store.dispatch('attemptUpdateIndividualWorkspace', { avatar: data[2].image })
                }
                this.$router.push({ name: 'individual.candidature.under.analysis', query: { help: 1 } })
              })
            })
          } catch (error) {
            console.log(error)
            this.$router.push({ name: 'workspace.selection.index' })
            // this.attemptingLogin = false
          }
        }
        this.changeLanguage(userData.language)
        this.$store.commit('setLanguageIsLoaded', true)
      } catch (error) {
        console.log(error)
        this.attemptingLogin = false
      }
    },
    uploadImage () {
      if (!this.formData.avatar) return
      var byteString = atob(this.formData.avatar.split(',')[1])
      var mimeString = this.formData.avatar.split(',')[0].split(':')[1].split(';')[0]
      // Validar com vuelidate
      if (mimeString !== 'image/jpeg' && mimeString !== 'image/png') return
      var ab = new ArrayBuffer(byteString.length)
      var ia = new Uint8Array(ab)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      var bb = new Blob([ab], { type: mimeString })
      const formData = new FormData()
      formData.append('url', process.env.VUE_APP_HIRE_API + 'v1/store/images/users/avatar')
      formData.append('file', bb)
      formData.append('name', 'avatar.png')
      formData.append('type', 'avatar')
      this.$emit('image-loading', true)
      this.isLoading = true
      return this.$store.dispatch('attemptPostUserProfilePhoto', formData)
    },
    sendForm () {
      if (this.$v.passwords.$error) return
      const createUserBody = {
        email: this.formData.email,
        language: this.prospectInfo.company.language,
        lastName: this.formData.lastName,
        name: this.formData.name,
        password: this.passwords.confirmPassword,
        passwordConfirmation: this.passwords.password,
        region: this.formData.region
      }
      this.isReady = false
      this.$store.dispatch('attemptRegisterUser', createUserBody).then(() => {
        this.login()
      })
    },
    getUpdatedSocialMedia () {
      const socialMedias = {}
      this.formData.socialMedia.forEach(socialmedia => {
        socialMedias[socialmedia.name.toLowerCase()] = socialmedia.value
      })
      return socialMedias
    },
    formatDateToTimeStamp (myDate) {
      myDate = myDate.split('/')
      var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]).toISOString()
      return newDate
    },
    getUpdatedPersonal () {
      const personalInfo = {}
      personalInfo.countryAbbr = this.formData.countryAbbr
      personalInfo.dateOfBirth = this.formData.birthdate !== '' ? this.formatDateToTimeStamp(this.formData.birthdate) : ''
      personalInfo.disability = this.formData.pcdType
      personalInfo.isDisabledPerson = !!this.formData.isPCD
      personalInfo.region = this.formData.region
      personalInfo.email = this.formData.email
      personalInfo.firstName = this.formData.name
      personalInfo.lastName = this.formData.lastName
      personalInfo.location = this.formData.city
      personalInfo.locationId = this.formData.cityId
      personalInfo.medicalReportICD = this.formData.pcdCID
      personalInfo.phone = `+${this.countryToCode(this.formData.countryCode)} ${this.formData.phone}`
      personalInfo.specialAdaptationNeeded = this.formData.pcdAdaptation
      personalInfo.title = this.formData.professionalTitle
      personalInfo.description = this.formData.professionalDescription
      return personalInfo
    },
    getUpdatedEducation () {
      if (this.formData.academicExperiences.length === 0) return []
      return this.formData.academicExperiences.map((academicExp) => {
        return { course: academicExp.title, id: academicExp.id, isStudying: academicExp.isStudying, periodFrom: this.formatYearToTimestamp(academicExp.dateInit), periodTo: this.formatYearToTimestamp(academicExp.dateEnd), school: academicExp.institution }
      })
    },
    getUpdatedLanguages () {
      const myObj = {}
      this.formData.languages.forEach(language => {
        myObj[language.name] = language.proficiency
      })
      return myObj
    },
    updateHandler () {
      const updateObject = { filter: 'social-links, work-experience, personal-details, education, categories, languages, skills' }
      updateObject.workExperience = this.formData.professionalExperiences.map((workexp) => {
        return { title: workexp.title, companyName: workexp.company, periodFrom: this.formatMonthYearToTimestamp(workexp.dateInit), periodTo: this.formatMonthYearToTimestamp(workexp.dateEnd), isCurrentWork: workexp.actualWork, id: workexp.id, description: workexp.description }
      })
      updateObject.socialLinks = this.getUpdatedSocialMedia()
      updateObject.personalDetails = this.getUpdatedPersonal()
      updateObject.education = this.getUpdatedEducation()
      updateObject.categories = this.formData.preferredAreas
      updateObject.languages = this.getUpdatedLanguages()
      updateObject.skills = this.formData.skills
      return updateObject
    },
    formatInfoToFormData (info) {
      if (info.user) {
        this.formData = { ...this.formData, ...info.user }
      } else {
        this.formData = { ...this.formData, ...info.profile }
      }
      this.formData.preferredAreas.push(info.position.category)
      this.changeLanguage(this.prospectInfo.company.language)
      if (!this.formData.countryCode) this.formData.countryCode = this.prospectInfo.company.region === 'BRA' ? 'br' : 'us'
      this.inviteId = info.id
    }

  },
  validations () {
    const validateBirthDate = (value) => {
      if (value === '') return true
      if (value.split('/').length !== 3) return false
      const [day, month, year] = value.split('/')

      if (!(day.length === 2) || !(month.length === 2) || !(year.length === 4)) return false

      const birthDate = new Date()
      birthDate.setMonth(month - 1)
      birthDate.setDate(day)
      birthDate.setFullYear(year)

      if (birthDate.getTime() > new Date().getTime()) return false
      const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
      if (!value.match(regex)) return false
      return true
    }
    const initEndDateYear = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const yearInit = parseInt(value.dateInit)
      const yearEnd = parseInt(value.dateEnd)
      if (yearInit > yearEnd) return false
      return true
    }
    const initEndDate = (value) => {
      if (value.actualWork || value.isStudying) return true
      if (!value.dateInit || !value.dateEnd) return true
      const monthInit = parseInt(value.dateInit.split('/')[0])
      const monthEnd = parseInt(value.dateEnd.split('/')[0])
      const yearInit = parseInt(value.dateInit.split('/')[1])
      const yearEnd = parseInt(value.dateEnd.split('/')[1])
      if (yearInit > yearEnd) return false
      if (yearInit === yearEnd && monthInit > monthEnd) return false
      return true
    }
    const notUsedEmail = (value) => {
      if (!this.formData.emailResponse) return true
      if (this.formData.emailResponse.status === 200) return false
      return true
    }
    const isEnliztEmail = (val) => {
      if (!this.formData.emailResponse) return true
      if (this.formData.emailResponse.status === 400 || this.formData.emailResponse.status === 403 || ['enlizt.com', 'enlizt.com.br', 'enlizt.br'].includes(val.split('@')[1])) return false
      return true
    }
    const validFormatMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const month = value.split('/')[0]
      const year = value.split('/')[1]
      if (month.length !== 2 || year.length !== 4) return false
      if (parseInt(month) > 12) return false
      return true
    }
    const limitDateMonthYear = (value) => {
      if (!value) return true
      if (!value.includes('/')) return false
      const actualYear = new Date().getUTCFullYear()
      const actualMonth = ('0' + (new Date().getUTCMonth() + 1)).slice(-2)
      const month = parseInt(value.split('/')[0])
      const year = parseInt(value.split('/')[1])
      if (year > actualYear) return false
      if (year === actualYear && month > actualMonth) return false
      return true
    }
    const validatePhoneDDD = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validDDD = [11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 63, 64, 65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99]
        const ddd = value.slice(0, 2)
        if (validDDD.indexOf(parseInt(ddd)) !== -1) return true
        return false
      } else return false
    }
    const validatePhoneNumber = (input) => {
      if (this.formData.countryCode !== 'br') return true
      if (!input) return false
      const value = input.replace('(', '').replace(')', '').replace(' ', '')
      if (value.length === 11) {
        var validFirstDigit = ['6', '7', '8', '9']
        const tel = value.slice(2)
        if (validFirstDigit.indexOf(tel[0]) !== -1) {
          return true
        }
        return false
      } else return false
    }
    const validateCountryAbbr = () => {
      if (this.formData.region !== 'BRA' || this.formData.region !== 'USA') {
        return true
      } else {
        return false
      }
    }
    const hasAcademicExperiencesValidation = (value) => {
      if (this.formData.academicExperiences.length > 0) {
        return true
      }
      return value === true
    }
    const hasProfessionalExperiencesValidation = (value) => {
      if (this.formData.professionalExperiences.length > 0) {
        return true
      }
      return value === true
    }
    const validateSocialMedias = (value) => {
      if (!value.name || !value.value) return false
      if (value.name === '' || value.value === '') return false
      const secureLink = this.socialMediaOptions[value.name]
      const startingLink = value.value.slice(0, secureLink.length)
      if (secureLink === startingLink) return true
      return false
    }
    return {
      passwords: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(20),
          passwordIsValid: function () {
            return this.passwordRulesValidation(this.passwords.password, 'valid')
          },
          passwordContainSpecialCharacter: function () {
            return this.passwordRulesValidation(this.passwords.password, 'special')
          },
          passwordContainUpperCharacter: function () {
            return this.passwordRulesValidation(this.passwords.password, 'upper')
          },
          passwordContainLowerCharacter: function () {
            return this.passwordRulesValidation(this.passwords.password, 'lower')
          },
          passwordContainNumber: function () {
            return this.passwordRulesValidation(this.passwords.password, 'number')
          }
        },
        confirmPassword: {
          required,
          maxLength: maxLength(20),
          passwordConfirmation: function () {
            return this.passwords.password === this.passwords.confirmPassword
          }
        }
      },
      formData: {
        name: { required },
        lastName: { required },
        birthdate: { validateBirthDate },
        countryAbbr: {
          validateCountryAbbr
        },
        city: { required },
        email: { required, email, notUsedEmail, isEnliztEmail },
        phone: { required, validatePhoneDDD, validatePhoneNumber },
        isPCD: '',
        pcdType: {
          required: requiredIf(function (formData) {
            return formData.isPCD
          })
        },
        pcdCID: {
          required: requiredIf(function (formData) {
            return formData.isPCD
          })
        },
        needAdaptation: '',
        pcdAdaptation: {
          required: requiredIf(function (formData) {
            return formData.needAdaptation
          })
        },
        socialMedia: {
          $each: { required, validateSocialMedias }
        },
        professionalTitle: { required },
        professionalDescription: { required },
        preferredAreas: { required },
        hasProfessionalExp: {
          hasProfessionalExperiencesValidation
        },
        professionalExperiences: {
          $each: {
            initEndDate,
            company: { required },
            title: { required },
            dateInit: { required, validFormatMonthYear, limitDateMonthYear },
            actualWork: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.actualWork
              }),
              validFormatMonthYear,
              limitDateMonthYear
            }
          }
        },
        hasAcademicExp: {
          hasAcademicExperiencesValidation
        },
        academicExperiences: {
          $each: {
            initEndDateYear,
            institution: { required },
            title: { required },
            dateInit: {
              required,
              limitDateYear: maxValue(new Date().getUTCFullYear())
            },
            isStudying: '',
            dateEnd: {
              required: requiredIf(function (experience) {
                return !experience.isStudying
              }),
              limitDateYear: maxValue(new Date().getUTCFullYear())
            }
          }
        },
        languages: {
          $each: {
            name: { required },
            proficiency: { required }
          }
        }
      }
    }
  },
  created () {
    this.formatInfoToFormData(this.prospectInfo)
  }
}
</script>
<template>
  <section class="firstAccess-selection--container">
    <v-container v-if="isReady" class="firstAccess--body">
        <div class="welcome-content-wrapper" v-if="step === 0">
          <div class="welcome-text-wrapper">
            <h1>{{$t('prospect.invite.new.welcome.title', {name:formData.name})}}</h1>
            <h5> {{$t('prospect.invite.new.welcome.subtitle')}}</h5>
            <div v-if="prospectInfo.notified || !!prospectInfo.origin">
              <div v-if="!prospectInfo.origin">
              <h5> {{$t('prospect.invite.new.welcome.notified.subtitle')}}</h5>
                <div class="welcome-button-wrapper">
                  <v-btn class="btn transform-unset" :color="'#1200D3'" @click="importNotifiedInfo" dark>{{$t('prospect.button.import')}}</v-btn>
                  <v-btn class="btn transform-unset" :color="'#1200D3'" @click="step = 1" dark>{{$t('prospect.button.fillManually')}}</v-btn>
                </div>
              </div>
              <div v-else>
                <h5> {{$t('prospect.invite.new.welcome.socialData.subtitle', {socialMediaName: prospectInfo.origin})}}</h5>
                <div class="welcome-button-wrapper">
                  <v-btn class="btn transform-unset" :color="'#1200D3'" @click="importNotifiedInfo" dark>{{$t('prospect.button.import')}}</v-btn>
                  <v-btn class="btn transform-unset" :color="'#1200D3'" @click="step = 1" dark>{{$t('prospect.button.fillManually')}} </v-btn>
                </div>
              </div>
            </div>
            <div class="welcome-button-wrapper" v-else-if="!prospectInfo.notified">
              <v-btn class="btn transform-unset" :color="'#1200D3'" @click="step = 1" dark>{{ $t('global:continue') }}</v-btn>
            </div>
            <Loading v-else></Loading>
          </div>
        </div>
      <div v-show="step === 1">
        <h2 class="first-selection--title mb-1">{{ $t('prospect.invite.new:form.title') }}</h2>
        <p class="first-subtitle" :class="'mb-10'">{{ $t('prospect.invite.new:form.subtitle') }}</p>
        <prospect-picture-manage v-model="formData.avatar" :themeColor="'#1200D3'" />
        <prospect-form v-model="formData" :language="prospectInfo.company.language" :validations="$v"></prospect-form>
        <div class="profile-form--actions">
          <v-btn class="btn transform-unset" :color="'#1200D3'" @click="handleForm" dark>{{ $t('global:continue') }}</v-btn>
        </div>
      </div>
      <div v-show="step === 2" class="password-wrapper">
         <h2 class="first-selection--title mb-1">{{ $t('prospect.invite.new:password.title') }}</h2>
        <p class="first-subtitle" :class="'mb-10'">{{ $t('prospect.invite.new:password.subtitle') }}</p>
        <section-block :title="$t('login.form:password')">
          <input-field type="password" v-model="passwords.password" maxLength="160" outlined persistent-placeholder :validation="$v.passwords.password" :label="$t('registration.form:password')"></input-field>
          <input-field type="password" v-model="passwords.confirmPassword" maxLength="160" outlined persistent-placeholder :validation="$v.passwords.confirmPassword" :label="$t('registration.form:confirmPassword')"></input-field>
          <div class="registration-form-checkbox">
            <v-checkbox
              class="body--1 mt-n4"
              v-model="formData.useTermConfirmation"
              hide-details="auto"
            />
            <p v-html="$t('registration.form:useTerm', { useTermsUrl })"></p>
          </div>
        </section-block>
        <div class="profile-form--actions">
          <v-btn class="btn transform-unset white--text" :disabled="$v.passwords.$invalid || !formData.useTermConfirmation" @click="sendForm" :color="'#1200D3'"><v-icon class="mr-2" size="20" color="#fff">mdi-content-save-outline</v-icon>{{ $t('global:save') }}</v-btn>
        </div>
      </div>
    </v-container>
    <Loading v-else></Loading>
  </section>
</template>
<style lang="scss">
.welcome-content-wrapper {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin:auto;
  padding: 0 15%;
  text-align: justify;
    h1 {
      font-family: $lato;
      font-size: 72px;
      font-weight: 700;
    }
    h5 {
      color: #A0A0A0;
      font-family: $lato;
      font-size: 24px;
      font-weight: 400;
    }
}
.welcome-button-wrapper {
  button {
    width: 200px;
  }
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.firstAccess-selection--container {
  margin: 0 5%;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  .firstAccess--header {
    display: flex;
    width: 100vw;
    height: 64px;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    position: absolute;
    top: 0;
    left: 0;

    img {
      height: 24px;
    }
  }
  .firstAccess--body {
    .password-wrapper {
      margin: auto;
      width: 60%;
    }
    .content-wrapper {
      display: flex;
      min-height: 100vh;
      min-width: 100vw;
      justify-content: center;
      align-items: center;
      margin-bottom: 200px;
    }
    .profile-form--actions {
      text-align: right;
      margin: 40px 0 80px;
    }
    max-width: 1100px;
    padding-top: 100px;
    .first-selection--title {
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #242321;
    }
    .first-subtitle {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #A0A0A0;
    }
    .first--stape {
      margin-top: 50px;
      text-align: left;
    }
  }

  .registration-form-checkbox {
        display: flex;
        align-items: center;
  }
}
</style>
